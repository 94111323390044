.ModuleMain {
  display: flex;
}

.ModuleFirst {
  width: 65%;
  padding: 0.5vw;
}

.ModuleSecond {
  width: 35%;
  padding: 0.5vw;
}

.ModuleImageContainer {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.ModuleImage {
  width: 100%;
}

.ModuleInfo {
  padding: 10px 0;
}

.ModuleTitle {
  font-weight: 500;
  font-size: 18px;
  color: var(--gray-900);
  line-height: 20px;
  margin: 0 0 15px 0;
}

.ModuleDescription {
  font-weight: 500;
  font-size: 15px;
  color: var(--gray-700);
  line-height: 18px;
  margin-bottom: 15px;
}

.LecturesContainer {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--gray-300);
}

.QuizLectureContainer {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--gray-300);
  margin-top: 10px;
}

.LectureWrapper {
  padding: 14px 8px;
  background-color: var(--gray-100);
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-overflow: ellipsis;
}

.BorderBottom {
  border-bottom: 1px solid var(--gray-300);
}

.ActionPlay {
  color: #14ae39;
}

.QuizQuestionWrapper {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.QuizTitle {
  font-weight: 600;
  font-size: 30px;
  color: var(--gray-900);
  line-height: 35px;
  margin: 0 0 10px 0;
}

.QuizSubTitle {
  font-weight: 500;
  font-size: 15px;
  color: var(--gray-500);
  line-height: 18px;
  margin-bottom: 20px;
  text-align: start;
}

.ButtonPrimary {
  border-radius: 3vw;
  background-color: var(--color-primary);
  padding: 10px 15px;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  width: fit-content;
}

.ButtonWrapper {
  gap: 10px;
  display: flex;
  margin-top: 20px;
}

.ButtonSecondary {
  border-radius: 3vw;
  background-color: transparent;
  padding: 10px 15px;
  color: var(--gray-500);
  border: 1px solid var(--color-primary);
  cursor: pointer;
}

.QuizQuestionOption {
  border: 2px solid var(--gray-500);
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 10px;
  color: var(--gray-700);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.QuizQuestion {
  font-weight: 500;
  font-size: 18px;
  color: var(--gray-800);
  line-height: 30px;
  margin-bottom: 20px;
  text-align: start;
}

.QuizStartWrapper {
  max-width: 50rem;
  width: 100%;
}

.QuizResultPassedHeader {
  background-color: #f7fbf9;
  color: #3f906b;
  border: 2px solid #3f906b;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
}

.QuizResultFailedHeader {
  background-color: #fdf5f5;
  color: #e25657;
  border: 2px solid #e25657;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
}

.QuizResultTitle {
  font-weight: 600;
  font-size: 20px;
  color: var(--gray-900);
  line-height: 30px;
  margin-bottom: 10px;
  text-align: start;
}

.QuizResultSubTitle {
  font-weight: 500;
  font-size: 16px;
  color: var(--gray-800);
}

.QuizTtleIconF {
  color: #e25657;
  cursor: pointer;
}

.QuizTtleIconP {
  color: #3f906b;
  cursor: pointer;
}

.ResultHeaderBtnContainer{
  margin-top: 20px;
}

.CorrectChip {
  background-color: #f7fbf9;
  color: #3f906b;
  border: 2px solid #3f906b;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.IncorrectChip {
  background-color: #fdf5f5;
  color: #e25657;
  border: 2px solid #e25657;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.LecturesStatusIcon {
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .ModuleMain {
    display: block;
  }

  .ModuleFirst {
    width: 100%;
    padding: 0;
  }

  .ModuleSecond {
    width: 100%;
    padding: 0;
  }

  /* .ModuleInfo {
    display: none;
  } */
}

@media (min-width: 576px) and (max-width: 768px) {
  .ModuleMain {
    display: block;
  }

  .ModuleFirst {
    width: 100%;
    padding: 0;
  }

  .ModuleSecond {
    width: 100%;
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .ModuleMain {
    display: block;
  }

  .ModuleFirst {
    width: 100%;
    padding: 0;
  }

  .ModuleSecond {
    width: 100%;
    padding: 0;
  }
}

@media (min-width: 992px) {
}
