.BulkImportInputContainer {
  margin: 1vh 0 1.5vh 0;
}

.BulkSuccessContainer {
  background-color: #f7fbf9;
  border: 2px solid #3f906b;
  border-radius: 8px;
  color: #3f906b;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5vh;
}

.BulkFailedContainer {
  background-color: #fdf5f5;
  border: 2px solid #e25657;
  border-radius: 8px;
  color: #e25657;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5vh;
}

.BulkResultInfo {
  display: flex;
  flex-direction: column;
  gap: 0.8vh;
}

.Button {
  width: fit-content;
  margin-top: 0;
}

.BulkResultCloseIcon {
    cursor: pointer;
}
