.NavBar {
  background-color: #fff;
  padding: 0.5vw 1.35vw;
  display: flex;
  justify-content: space-between;
}

.DashBoard {
  padding: 1.35vw;
}

.ProfilePreview {
  background-color: var(--gray-50);
  border: 1px solid #5f6368;
  border-radius: 8px;
  padding: 0.8vh;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: 600;
  color: var(--color-primary);
  gap: 0.8vh;
}

.CompanyLogoWrapper {
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}

.CompanyLogo {
  height: 30px;
}

.ProfileImageWrapper {
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #5f6368;
  overflow: hidden;
}

.ProfileImage {
  height: 30px;
  width: 30px
}

.NotificationIconwrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.NotificationDot {
  background: #ff6767;
  border-radius: 50%;
  font-size: 10px;
  color: white;
  position: absolute;
  top: -5px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  width: 17px;
}

.Notification {
  border-radius: 0.75rem;
}

.Notification .Time {
  color: var(--gray-500);
  font-size: 0.625vw;
  margin-top: 0.301vw;
  margin-left: auto;
  font-weight: 400;
}

.NotificationTitle {
  color: var(--gray-900);
  font-size: 16px;
  font-weight: 600;
}

.Notification .SubTitle {
  font-size: 12px;
  color: var(--gray-700);
  font-weight: 500;
}

.NotificationImageWrapper {
  display: flex;
  align-items: center;
}

.TimeAndSource {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MenuItems {
  padding: 0.5vh 1vw;
  width: max-content;
  grid-column-gap: 0.7vw;
  display: flex;
  align-items: center;
}

.Title {
  font-size: 1vw;
  font-weight: 600;
  color: var(--color-primary);
}

.NavBarStart {
  display: flex;
  align-items: center;
}

.RoleName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.UserRole {
  font-size: 13px;
  font-weight: 500;
}

.UserName {
  font-size: 17px;
  font-weight: 600;
}

.ProfileMenu {
  border-radius: 0.75rem;
}

.ProfileMenuItems {
  font-size: 15px;
}

.FieldControl input {
  margin-top: 0;
}