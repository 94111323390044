.UWrapper1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.UWrapper2 {
  max-width: 500px;
  flex-grow: 1;
  padding: 30px;
}

.UFormTitle {
  font-weight: 600;
  font-size: 1.2rem;
}

.UForm {
  box-sizing: border-box;
}

.UForm input {
  display: block;
  width: 100%;
  height: calc(1em + 1rem + 2px);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1e2022;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d5dae2;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.UForm label {
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
}

.USelect {
  width: 100%;
}

.ErrorMsg {
  color: #e25657;
  cursor: pointer;
  font-size: 12px;
  text-align: start;
}

.Divider {
  border-bottom: 2px solid var(--gray-300);
  margin: 30px 0;
}

.Button {
  border-radius: 3vw;
  background-color: var(--color-primary);
  padding: 10px 15px;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: 12px;
  margin-top: 20px;
}
