.MainContainer {
  background: linear-gradient(to top, var(--color-primary), #fa8989);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-family: sans-serif;
  line-height: 1.4;
  display: flex;
  height: 100vh;
}

.Container {
  width: 400px;
  margin: auto;
  padding: 36px 48px 48px 48px;
  background-color: #f2efee;
  border-radius: 11px;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
}

.LoginTitle {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

.LoginForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.InputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.LoginForm label {
  display: block;
  margin-bottom: 8px;
}

.LoginForm input {
  padding: 1.2rem;
  border-radius: 9px;
  border: none;
  flex-grow: 1;
  width: 90%;
}

.LoginForm input:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(253, 242, 233, 0.5);
}

.ButtonFrom {
  background-color: var(--color-primary);
  outline: 1px solid #f48982;
  color: #fdf2e9;
  align-self: end;
  padding: 8px;
  width: 100%;
}

.ButtonFrom,
.ButtonFrom:link,
.ButtonFrom:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  border-radius: 9px;
  border: none;
  cursor: pointer;
  font-family: inherit;
  transition: all 0.3s;
}

.ButtonFrom:hover {
  background-color: #fdf2e9;
  color: #f48982;
}

.Logo {
  height: 5vh;
}

.Logowrapper {
  display: flex;
  justify-content: center;
}

.SubTitle {
  letter-spacing: 0;
  color: #818e94;
  font-size: 14px;
  margin-top: 0.72vh;
  margin-bottom: 2.23vh;
}

.LoginWrapper {
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .Container {
    width: 100%;
  }

  .LoginForm input {
    width: 86%;
  }
}