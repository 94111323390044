.Border {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  opacity: 0.5;
}

.Sidebar {
  height: 100vh;
  position: relative;
  z-index: 1;
  width: 6vw;
}

.Container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  transition: width 0.3s ease-in-out;
  background-color: var(--color-primary);
}

.Container:hover {
  transition-delay: 0.3s;
  width: 16.5vw;
}

.Content {
  padding: 1.5vw;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.LogoContainer {
  height: 10vh;
}

.Title {
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 2vw;
}

.ImageWarpper {
  text-align: center;
  max-width: 90%;
  max-height: inherit;
}

.PoweredBy {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.7vw;
  color: white;
}

.LogoImage {
  height: auto;
  max-width: inherit;
  max-height: initial;
}

.AllRoutes {
  width: 100%;
}

.RoutesContainer {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.RoutesContainerHovered {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;
}

.RouteParent {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.ArrowIcon {
  position: absolute;
  right: 0;
  color: white;
}

.RouteWrapper {
  display: flex;
  color: white;
  justify-content: center;
  background-color: rgb(255, 255, 255, 0.23);
  height: 3vw;
  width: 3vw;
  border-radius: 50%;
  align-items: center;
  margin-bottom: 0.5vh;
  transition: background-color 0.3s linear;
  cursor: pointer;
}

.RouteWrapperHovered {
  display: flex;
  color: white;
  justify-content: flex-start;
  height: 3vw;
  width: 100%;
  margin-bottom: 0.5vh;
  align-items: center;
  grid-column-gap: 1vw;
  border-radius: 0.781vw;
  font-size: 0.8vw;
  transition: background-color 0.3s linear;
  cursor: pointer;
}

.RouteWrapperActive {
  display: flex;
  color: white;
  justify-content: flex-start;
  background-color: rgb(255, 255, 255, 0.23);
  height: 3vw;
  width: 100%;
  margin-bottom: 0.5vh;
  transition: width 0.3s ease-in-out;
  align-items: center;
  grid-column-gap: 1vw;
  border-radius: 0.781vw;
  font-size: 0.8vw;
  cursor: pointer;
}


.RouteImageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RouteIcon {
  height: 1.2vw;
  width: 1.2vw;
}

.MenuWrapper {
  background-color: var(--color-primary);
  padding: 0.2vw;
  border-radius: 10px;
  border: 1px solid rgb(255, 255, 255, 0.23);
  ;
}

.MenuWrapperArrow {
  background-color: var(--color-primary);
}

.MenuItemClassName {
  color: white;
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  grid-column-gap: 0.7vw;
  padding: 0.8vh 0.5vw;

}

.MenuItemClassName:hover {
  background-color: rgb(255, 255, 255, 0.23);
  border-radius: 0.581vw;
}

.ChildRouteIcon {
  height: 1vw;
  width: 1vw;
}