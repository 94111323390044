.Thumbnail {
  width: 120px;
}

.CreateModal {
  width: 25vw;
}

.ModulesWrapper {
  display: flex;
  align-items: center;
  gap: 1%;
  flex-wrap: wrap;
  margin-top: 2rem;
  align-items: flex-start;
}

.ModuleContainer {
  width: 24%;
  border-radius: 8px;
  overflow: hidden;
  align-self: stretch;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1%;
  position: relative;
  transition: all 0.1s ease-in-out;
}

.ModuleContainer:hover {
  box-shadow: 0 6px 8px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-in-out;
}

.ModuleImageWrapper {
  width: 100%;
}

.ModuleImage {
  width: 100%;
}

.ModuleInfo {
  padding: 0 0.6vw 0.6vw;
  display: flex;
  gap: 0.5vw;
  justify-content: space-between;
  align-items: center;
}

.ModuleTitle {
  font-weight: 500;
  font-size: 0.9vw;
  color: var(--gray-700);
  line-height: 1.3vw;
}

.ActionContainer {
  position: absolute;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid var(--gray-600);
  right: 0.5vw;
  top: 0.5vw;
  padding: 5px;
  transition: all 0.1s ease-in-out;
  visibility: visible;
}

.ActionContainerNo {
  position: absolute;
  right: 0.5vw;
  top: 0.5vw;
  visibility: hidden;
  transition: all 0.1s ease-in-out;
}

.ActionContainerNew {
  display: flex;
  gap: 0.5vw;
  justify-content: center;
  align-self: center;
}

.EditFirst {
  width: 50%;
  padding: 0.5vw;
}

.EditSecond {
  width: 50%;
  padding: 0.5vw;
}

.FromContainer {
  height: 80%;
  overflow-y: scroll;
}

.EditMain {
  display: flex;
}

.EditImageContainer {
  width: 100%;
}

.EditImage {
  width: 100%;
}

.EditTitle {
  font-weight: 500;
  font-size: 1.3vw;
  color: var(--gray-900);
  line-height: 1.3vw;
  margin: 0 0 15px 0;
}

.EditDescription {
  font-weight: 500;
  font-size: 0.9vw;
  color: var(--gray-700);
  line-height: 1.3vw;
  margin-bottom: 15px;
}

.AccordContainer {
  max-height: 60vh;
  overflow-y: scroll;
}

.LecturesContainer {
  border-radius: 8px;
  overflow: hidden;
}

.AccordItem {
  border: 1px solid var(--gray-200);
}

.AccordButton {
  padding: 14px 8px;
  background-color: var(--gray-100);
  cursor: pointer;
  font-size: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AccordActionButton {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}

.CButtonsContainer {
  margin-bottom: 10px;
}

.CButtonsContainer button {
  margin-top: 0;
}

.UploadStatus {
  font-size: 0.9vw;
}

.UploadWaiting {
  font-size: 0.9vw;
  color: #e2a00d;
}

.UploadUploading {
  font-size: 0.9vw;
  color: #01b0f1;
}

.UploadSuccess {
  font-size: 0.9vw;
  color: #4fba46;
}

.UploadError {
  font-size: 0.9vw;
  color: #d41010;
}

.ErrorPreview {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 3px 10px;
  font-size: 0.8vw;
  color: #e74c3c;
  border: 1px solid #e74c3c;
}

.FisrtEmpty {
  height: 2vw;
  margin-bottom: 10px;
}

.FisrtWrapper {
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  overflow: hidden;
}

.EditInfo {
  padding: 10px;
}

.QuizQTitle {
  margin: 10px 0 5px 0;
}

.QuizQQWrapper {
  margin-bottom: 25px;
}

.TableLinkCell {
  text-decoration: underline;
  font-weight: 700;
}

.Button {
  width: fit-content;
  margin-top: 0;
}
