.NavContainer {
  box-sizing: border-box;
}

.Nav {
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: relative;
}

.CompanyLogo {
  height: 30px;
}

.Nav > .NavHeader {
  display: inline;
}

.Nav > .NavHeader > .NavTitle {
  display: flex;
  font-size: 22px;
  color: #fff;
}

.Nav > .NavBtn {
  display: none;
}

.Nav > .NavLinks {
  display: flex;
  float: right;
  font-size: 16px;
  z-index: 1;
  gap: 1vw;
}

.Nav > .NavLinks > a {
  display: inline-block;
  text-decoration: none;
  color: #efefef;
  cursor: pointer;
  font-weight: 600;
  padding: 5px;
}

.Nav > .NavLinks > a:hover {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.Nav > .NavCheck {
  display: none;
}

.ToggleIcon {
  color: #eee;
  margin: 0 10px;
  z-index: 1;
}

.Nav2 {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #ffffff;
  width: 100%;
}

.Nav2BackContainer {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.Nav2BackContainer:hover {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.Nav2Title {
  overflow: hidden;
  white-space: nowrap;
  width: 85%;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .Nav > .NavBtn {
    display: inline-block;
    z-index: 1;
  }

  .Nav > .NavHeader > .NavTitle {
    display: flex;
    font-size: 22px;
    color: #fff;
    position: absolute;
    width: 95%;
    justify-content: center;
    top: 11px;
    margin: 0;
  }

  .Nav > .NavLinks {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #333;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }

  .Nav > .NavLinks > a {
    display: block;
    width: 100%;
    text-align: center;
    margin: 20px 0;
    padding: 0;
  }

  .Nav > .NavCheck:not(:checked) ~ .NavLinks {
    height: 0px;
  }

  .Nav > .NavCheck:checked ~ .NavLinks {
    height: calc(100vh - 50px);
    overflow: hidden;
  }
}
