.TopBannerImageContainer {
  background-image: url("https://ariel-frontend.s3.amazonaws.com/arielbanner.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 44vh;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.TopBannerTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 40%;
}

.TopBannerTextTitle {
  font-weight: 600;
  margin: 0;
}

.TopBannerTextSubTitle {
  font-weight: 600;
  color: #727180;
  margin: 20px 0;
}

.TopBannerButton {
  border-radius: 3vw;
  background-color: var(--color-primary);
  padding: 10px 15px;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: 12px;
}

.SectionContainer {
  margin-bottom: 30px;
}

.SectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SectionTitle {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}

.EmptyModule {
  text-align: center;
  font-size: 13px;
}

@media (max-width: 576px) {
  .TopBannerTextTitle {
    font-size: 26px;
    line-height: 30px;
    width: 60%;
  }

  .TopBannerTextSubTitle {
    font-size: 14px;
    width: 54%;
  }

  .TopBannerImageContainer {
    height: 55vh;
  }

  .TopBannerTextContainer {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .TopBannerTextTitle {
    font-size: 32px;
    line-height: 37px;
  }

  .TopBannerTextSubTitle {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .TopBannerTextTitle {
    font-size: 32px;
    line-height: 37px;
    font-weight: 600;
    margin: 0;
  }

  .TopBannerTextSubTitle {
    font-size: 16px;
    font-weight: 600;
    color: #727180;
    margin: 20px 0;
  }
}

@media (min-width: 992px) {
  .TopBannerTextTitle {
    font-size: 42px;
    line-height: 50px;
    font-weight: 600;
    margin: 0;
  }

  .TopBannerTextSubTitle {
    font-size: 20px;
    font-weight: 600;
    color: #727180;
    margin: 20px 0;
  }
}

/* @media (min-width: 1200) {}

@media (min-width: 1400) {} */
