.ChartParent {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 2vh 0;
  padding: 1vw 1.2vw;
  flex-grow: 1;
}

.ChartWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 40vh;
}

.ChartChart {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
}

.ChartInfo {
  flex-grow: 1;
}

.ChartMain {
  display: flex;
  gap: 1vw;
}

.ChartHeadWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;
}

.DnButton {
  cursor: pointer;
  color: var(--color-primary);
}
