.checkboxWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.8vw;
  user-select: none;
}

.label {
  margin-left: 0.2vw;
}

.inputWrapper {
  position: relative;
}

.input {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  margin: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

.input:checked+.checkmark {
  background-color: var(--color-primary);
  border: 0.1vw solid var(--color-primary);
}

.input:checked+.checkmark:after {
  display: block;
}

.checkmark {
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #DEE4EB;
  border-radius: 5px;
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}