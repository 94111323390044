.ModuleContainer {
  width: 95%;
  border-radius: 8px;
  overflow: hidden;
  align-self: stretch;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  transition: all 0.1s ease-in-out;
}

.ModuleContainer:hover {
  box-shadow: 0 6px 8px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-in-out;
}

.ModuleImageWrapper {
  width: 100%;
}

.ModuleImage {
  width: 100%;
}

.ModuleInfo {
  padding: 0 0.6vw 0.6vw;
  display: flex;
  gap: 0.5vw;
  justify-content: space-between;
  align-items: center;
}

.ModuleTitle {
  font-weight: 500;
  font-size: 13px;
  color: var(--gray-700);
}

.ModuleWrapper {
  display: flex;
  width: 300px;
  height: 100%;
}

.ModuleProgress {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .ModuleInfo {
    padding: 0 1.5vw 1.5vw;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
}

@media (min-width: 768px) and (max-width: 992px) {
}

@media (min-width: 992px) {
}
